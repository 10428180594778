import { graphql, useStaticQuery } from 'gatsby'
import React, { ReactElement } from 'react'

import NewsAndEventsLayout from '../../components/Layout/NewsAndEvents'
import NewsAndEvents from '../../components/NewsAndEventsPage/NewsAndEvents'
import Hero from '../../components/NewsAndEventsPage/Hero'
import NoData from '../../components/NoData'

import SEO from '../../components/SEO/SEO'

const NewsAndEventsPage = (): ReactElement => {
  const featuredNewsAndEvents = useStaticQuery(graphql`
    query featuredNewsAndEvents {
      allSitePage(filter: { context: { category: { eq: "news-and-events" } } }) {
        edges {
          node {
            path
            context {
              data {
                id
                type
                description
                title
                updated
                feature
                featuredMedia {
                  media
                  mediaType
                }
              }
            }
          }
        }
      }
    }
  `)

  const data = featuredNewsAndEvents.allSitePage.edges
  const featured = data.filter((res: any) => res.node.context.data.feature === true)
  const noData = data.length > 0 ? false : true

  return (
    <NewsAndEventsLayout noData={noData}>
      <SEO title="NEWS AND EVENTS - ALISI | Ayala Land" />
      {!noData ? (
        <>
          <Hero data={featured.length > 0 ? featured[0].node : data[0].node} />
          <NewsAndEvents />
        </>
      ) : (
        <NoData withBackground={true} title="news and events" />
      )}
    </NewsAndEventsLayout>
  )
}

export default NewsAndEventsPage
